<template>
  <a-modal
    v-model:visible="visible"
    destroyOnClose
    centered
    :keyboard="false"
    width="800px"
    :class="['info-dialog', 'info-dialog-temp-' + tempId]"
    :footer="false"
    :bodyStyle="{ padding: 0, background: 'transparent' }"
    @cancel="handleCancel"
  >
    <div class="info-dialog-content">
      <div class="head"></div>
      <div class="content yellow">
        <div class="title">{{ $t("XB_RankingList") }}</div>
        <!-- 排行榜 -->
        <div class="vhtml">
          <div class="rank">
            <div class="top-three">
              <div class="top-three-item">
                <template v-if="topThree.length >= 2">
                  <div class="sort sort-2">
                    <img :src="topThree[1].portrait" alt="avatar" />
                  </div>
                  <div class="name">
                    <OpenData type="userName" :openid="topThree[1].realName" />
                  </div>
                  <div class="progress">{{ topThree[1].progress }}%</div>
                </template>
              </div>
              <div class="top-three-item" v-if="topThree.length >= 1">
                <div class="sort sort-1">
                  <img :src="topThree[0].portrait" alt="avatar" />
                </div>
                <div class="name">
                  <OpenData type="userName" :openid="topThree[0].realName" />
                </div>
                <div class="progress">{{ topThree[0].progress }}%</div>
              </div>
              <div class="top-three-item">
                <template v-if="topThree.length >= 3">
                  <div class="sort sort-3">
                    <img :src="topThree[2].portrait" alt="avatar" />
                  </div>
                  <div class="name">
                    <OpenData type="userName" :openid="topThree[2].realName" />
                  </div>
                  <div class="progress">{{ topThree[2].progress }}%</div>
                </template>
              </div>
            </div>
            <div class="mine">
              {{ $t("map.my_rank_tip", 0) }}
              <!-- 共 -->
              <i>{{ topThree.length + other.length }}</i>
              {{ $t("map.my_rank_tip", 1) }}
              <!-- 名学员一同学习，我的排名是 -->
              <i> {{ ranking }}</i>
              {{ $t("map.my_rank_tip", 2) }}
              <!-- ，打败了 -->
              <i> {{ percentage }}%</i>
              {{ $t("map.my_rank_tip_2") }}
              <!-- 的学友~ -->
            </div>
            <div class="other">
              <div class="other-item" v-for="item in other" :key="item.userId">
                <img :src="item.portrait" alt="avatar" />
                <span class="name"
                  ><OpenData type="userName" :openid="item.realName"
                /></span>
                <span class="progress">{{ item.progress }}%</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>
<script>
import { defineComponent, reactive, toRefs, watch } from "vue";
import { mapRanking } from "@/api/map";
import OpenData from "@/components/OpenData.vue";

export default defineComponent({
  components: {
    OpenData,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    tempId: {
      type: Number,
      default: 1,
    },
    taskId: {
      type: Number,
      default: 0,
    },
    userId: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      visible: false,
      topThree: [],
      other: [],
      ranking: 0,
      percentage: 0,
    });

    watch(
      () => props.isVisible,
      (newVal) => {
        state.visible = newVal;
        if (newVal) {
          getRanking();
        }
      }
    );

    // 排行榜
    const getRanking = () => {
      mapRanking(props.taskId).then((res) => {
        let d = res.data || [];
        if (d.length) {
          for (let i = 0; i < d.length; i++) {
            if (d[i].userId == props.userId) {
              state.ranking = i + 1;
            }
          }
          if (state.ranking == 0) {
            state.percentage = 0;
          } else {
            state.percentage = (
              ((d.length - state.ranking) / d.length) *
              100
            ).toFixed(0);
          }
          state.topThree = d.slice(0, 3);
          if (d.length > 3) {
            state.other = d.slice(3);
          }
        }
      });
    };

    const handleCancel = () => {
      emit("cancal");
    };

    return {
      ...toRefs(state),
      handleCancel,
    };
  },
});
</script>
<style lang="less">
.info-dialog {
  .ant-modal-content {
    background: transparent;
    .ant-modal-close-x {
      font-size: 26px;
      color: #c18805;
    }
  }
  &-content {
    position: relative;
    .head {
      background-color: #febd27;
      height: 32px;
      position: absolute;
      top: -16px;
      left: -16px;
      right: -16px;
      border-radius: 16px;
      z-index: -1;
    }
    .content {
      background-color: #fff;
      height: 560px;
      ::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }
      ::-webkit-scrollbar-button:vertical {
        display: none;
      }
      ::-webkit-scrollbar-track,
      ::-webkit-scrollbar-corner {
        background-color: #fff;
      }
      ::-webkit-scrollbar-thumb {
        border-radius: 0;
        background-color: rgba(193, 136, 5, 0.75);
      }
      ::-webkit-scrollbar-thumb:vertical:hover {
        background-color: rgba(193, 136, 5, 0.75);
      }
      ::-webkit-scrollbar-thumb:vertical:active {
        background-color: rgba(193, 136, 5, 0.78);
      }
      .title {
        text-align: center;
        font-size: 30px;
        line-height: 56px;
        margin: 0;
        color: #c18805;
      }
      .vhtml {
        height: 504px;
        padding: 20px;
        overflow-y: scroll;
      }
      &.yellow {
        background: linear-gradient(
          to bottom,
          #f9dca4 2%,
          #fbedd4 5%,
          #fcf3e6 8%,
          #fcf3e6
        );
        .rank {
          background-color: #fff;
          height: 100%;
          .top-three {
            padding: 20px 120px;
            .mixinFlex(space-around; flex-end);
            &-item {
              min-width: 60px;
              .sort {
                width: 60px;
                height: 81px;
                background-size: contain;
                background-repeat: no-repeat;
                position: relative;
                img {
                  width: 54px;
                  height: 54px;
                  border-radius: 50%;
                  position: absolute;
                  bottom: 3px;
                  left: 3px;
                }
                &.sort-1 {
                  width: 80px;
                  height: 108px;
                  background-image: url("../preview/static/phb-1.png");
                  img {
                    width: 72px;
                    height: 72px;
                    border-radius: 50%;
                    position: absolute;
                    bottom: 6px;
                    left: 4px;
                  }
                }
                &.sort-2 {
                  background-image: url("../preview/static/phb-2.png");
                }
                &.sort-3 {
                  background-image: url("../preview/static/phb-3.png");
                }
              }
              .name {
                color: #a9853c;
                text-align: center;
                margin-top: 8px;
              }
              .progress {
                color: #97d455;
                font-size: 30px;
                text-align: center;
              }
            }
          }
          .mine {
            background-color: #fcf3e6;
            color: #c18805;
            height: 60px;
            line-height: 56px;
            margin: 0 30px;
            padding: 0 18px;
            i {
              padding: 0 5px;
              font-size: 24px;
              font-style: normal;
              color: #febd27;
            }
          }
          .other {
            margin: 0 30px;
            padding-top: 10px;
            &-item {
              .mixinFlex(space-between; center);
              font-size: 14px;
              height: 40px;
              margin-top: 10px;
              img {
                width: 40px;
                height: 40px;
                border-radius: 50%;
              }
              .name {
                color: #c18805;
                width: 80%;
              }
              .progress {
                color: #97d455;
              }
            }
          }
        }
      }
    }
  }
  &.info-dialog-temp-1 {
    .ant-modal-content {
      .ant-modal-close-x {
        color: #9d7621;
      }
    }
    .info-dialog-content {
      .head {
        background-color: #a9853c;
      }
      .content {
        .title {
          color: #a9853c;
        }
        &.yellow {
          background: linear-gradient(
            to bottom,
            #f9eed8 2%,
            #fffaed 5%,
            #fdf7ea 8%,
            #fdf7ea
          );
        }
      }
    }
  }
  &.info-dialog-temp-2 {
    .ant-modal-content {
      .ant-modal-close-x {
        color: #5b4e7f;
      }
    }
    .info-dialog-content {
      .head {
        background-color: #8f67f7;
      }
      .content {
        ::-webkit-scrollbar-thumb {
          border-radius: 0;
          background-color: rgba(143, 103, 247, 0.75);
        }
        ::-webkit-scrollbar-thumb:vertical:hover {
          background-color: rgba(143, 103, 247, 0.75);
        }
        ::-webkit-scrollbar-thumb:vertical:active {
          background-color: rgba(143, 103, 247, 0.78);
        }
        .title {
          color: #5b4e7f;
        }
        &.yellow {
          background: linear-gradient(
            to bottom,
            #cdc1ee 2%,
            #e5def7 5%,
            #f8f5ff 8%,
            #f8f5ff
          );
          .rank {
            .top-three {
              &-item {
                .name {
                  color: #5b4e7f;
                }
              }
            }
            .mine {
              background-color: #f8f5ff;
              color: #5b4e7f;
              i {
                color: #cdc1ee;
              }
            }
            .other {
              &-item {
                .name {
                  color: #5b4e7f;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
